import React from "react";

import { useEffect, useState } from "react"
import AuthUser from './AuthUser';
import FormWizard from "react-form-wizard-component";
import 'react-form-wizard-component/dist/style.css';

     

export default function Home() {
    const {http,setToken} = AuthUser();
    const [name,setName] = useState();
    const [email,setEmail] = useState();
    const [business,setBusiness] = useState();
    const [sell,setSell] = useState();
    const [goals,setGoals] = useState();
    const [market,setMarket] = useState();
    const [password,setPassword] = useState();
    const [error,setError] = useState();
    const formWizardRef = React.createRef();


    

    const submitForm = () =>{
        // api call
        http.post('/login',{email:email,password:password}).then((res)=>{


            setToken(res.data.user,res.data.access_token);
        }).catch(error => {
            alert("Invalid Username / Password");

        });
    }

    function changeName(e) {
      setError("");
      
      let input_name = document.getElementById("first_name").value;
      setName(input_name);
      

     }

     function changeEmail(e) {
      setError("");
      
      let input_name = document.getElementById("email").value;
      setEmail(input_name);
      

     }

    

     function updateLabel(e) {
      
     setError("");
     
     let input_name = "";
     if(document.getElementById("business_b2b").checked) {
        input_name="B2B Business";
     }
     else if(document.getElementById("business_b2c").checked) {
        input_name="B2C Business";  
     }
     
      setBusiness(input_name);
        

     }

     function updateLabelSell(e) {
        setError("");
      
    //  fetchDataApi(e.target.value);
      
  //  const collection = document.getElementsByClassName('option-container-sell');

    
   // const myElements = Array.from(collection);



    
  /*  myElements.forEach((element) => {
        console.log(element);
      element.classList.remove('highlight');
    });

     
      e.target.parentElement.classList.add('highlight'); */


      let input_name = "";
     if(document.getElementById("sell_products").checked) {
        input_name="Products";
     }
     else if(document.getElementById("sell_services").checked) {
        input_name="Services";  
     }
     
      setSell(input_name);
     

          
        

     }


     function updateLabelGoals(e) {
      setError("");
    //  fetchDataApi(e.target.value);
      
    


      let input_name = "";
     if(document.getElementById("goal_more_lead").checked) {
        input_name="More Leads";
     }
     else if(document.getElementById("goal_more_sales").checked) {
        input_name="More Sales";  
     }
     else if(document.getElementById("goal_increase_conversion").checked) {
        input_name="Increase Conversion";  
     }
     else if(document.getElementById("goal_build_brand").checked) {
        input_name="Build Our Brand";  
     }
     else if(document.getElementById("goal_secure_investment").checked) {
        input_name="Secure Investment";  
     }
     else if(document.getElementById("goal_other").checked) {
        input_name="Other";  
     }
     
      setGoals(input_name);
     

          
        

     }


     function updateLabelMarket(e) {
      
    setError("");


      let input_name = "";
     if(document.getElementById("marketing_google").checked) {
        input_name="Google Paid Search";
     }
     else if(document.getElementById("marketing_seo").checked) {
        input_name="SEO";  
     }
     else if(document.getElementById("marketing_email").checked) {
        input_name="Email Marketing";  
     }
     else if(document.getElementById("marketing_social_media").checked) {
        input_name="Social Media";  
     }
     else if(document.getElementById("marketing_referrals").checked) {
        input_name="Referrals";  
     }
     else if(document.getElementById("marketing_sales").checked) {
        input_name="Sales Presentations";  
     }
     
      setMarket(input_name);
     

          
        

     }

     useEffect(()=>{
        

        if(business!==undefined) {
            document.getElementById("top-bar-text-business").style.display="inline-block";
        }
        if(sell!==undefined) {
            document.getElementById("top-bar-text-sell").style.display="inline-block";
        }
        if(market!==undefined) {
            document.getElementById("top-bar-text-market").style.display="inline-block";
        }
        if(goals!==undefined) {
            document.getElementById("top-bar-text-goals").style.display="inline-block";
        }
       
        
        
    },[business,sell,goals,market,formWizardRef]);

    const handlerSubmit = (event) => {
      // changing the state to the name of the key
    // which is pressed
        if (event.key === 'Enter') {
         submitForm();
        } else {
         
        }
    };

    const handleComplete = () => {
    console.log("Form completed!");
    // Handle form completion logic here

    console.log(formWizardRef);
    if(business==="" || business===undefined) {
        formWizardRef.current?.goToTab(0);
        setError("Please describe your business");
        console.log("x");
    } else if(sell==="" || sell===undefined) {
        formWizardRef.current?.goToTab(1);
        setError("Please select Products or Services");
        console.log("x");
    } else if(goals==="" || goals===undefined) {
        formWizardRef.current?.goToTab(2);
        setError("Please select your video marketing goals");
        
        console.log("x");
    } else if(market==="" || market===undefined) {
        formWizardRef.current?.goToTab(3);
        setError("Please select how you market your business");
        
        console.log("x");
    } else {


        if(business=="B2B Business" && sell =="Products"  &&  market=="Google Paid Search") {
            window.location.href = 'https://mosaicmediafilms.com/b2b-products-ppc/';
        }

        if(business=="B2B Business" && sell =="Products"  &&  market=="SEO") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-products-seo-2/';
        }

        if(business=="B2B Business" && sell =="Products"  &&  market=="Email Marketing") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-products-email/';
        }

        if(business=="B2B Business" && sell =="Products"  &&  market=="Social Media") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-products-social/';
        }

        if(business=="B2B Business" && sell =="Products"  &&  market=="Referrals") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-products-referrals-2/';
        }

        if(business=="B2B Business" && sell =="Products"  &&  market=="Sales Presentations") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-products-sales/';
        }






        if(business=="B2B Business" && sell =="Services"  &&  market=="Google Paid Search") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-services-ppc-2/';
        }

        if(business=="B2B Business" && sell =="Services"  &&  market=="SEO") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-services-seo-2/';
        }

        if(business=="B2B Business" && sell =="Services"  &&  market=="Email Marketing") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-services-email/';
        }

        if(business=="B2B Business" && sell =="Services"  &&  market=="Social Media") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-services-social/';
        }

        if(business=="B2B Business" && sell =="Services"  &&  market=="Referrals") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-services-referrals/';
        }

        if(business=="B2B Business" && sell =="Services"  &&  market=="Sales Presentations") {
                window.location.href = 'https://mosaicmediafilms.com/b2b-services-sales/';
        }




         if(business=="B2C Business" && sell =="Products"  &&  market=="Google Paid Search") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-products-ppc-2/';
        }

        if(business=="B2C Business" && sell =="Products"  &&  market=="SEO") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-products-seo-2/';
        }

        if(business=="B2C Business" && sell =="Products"  &&  market=="Email Marketing") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-products-email/';
        }

        if(business=="B2C Business" && sell =="Products"  &&  market=="Social Media") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-products-social/';
        }

        if(business=="B2C Business" && sell =="Products"  &&  market=="Referrals") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-products-referrals-2/';
        }

        if(business=="B2C Business" && sell =="Products"  &&  market=="Sales Presentations") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-products-sales/';
        }





         if(business=="B2C Business" && sell =="Services"  &&  market=="Google Paid Search") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-services-ppc-2/';
        }

        if(business=="B2C Business" && sell =="Services"  &&  market=="SEO") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-services-seo-2/';
        }

        if(business=="B2C Business" && sell =="Services"  &&  market=="Email Marketing") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-services-email/';
        }

        if(business=="B2C Business" && sell =="Services"  &&  market=="Social Media") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-services-social/';
        }

        if(business=="B2C Business" && sell =="Services"  &&  market=="Referrals") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-services-referrals-2/';
        }

        if(business=="B2C Business" && sell =="Services"  &&  market=="Sales Presentations") {
                window.location.href = 'https://mosaicmediafilms.com/b2c-service-sales/';
        }
    }
    

    
  };

    const tabChanged = ({
    prevIndex,
    nextIndex,
  }: {
    prevIndex: number;
    nextIndex: number;
  }) => {
    console.log("prevIndex", prevIndex);
    console.log("nextIndex", nextIndex);
    if(nextIndex==2) {
        //alert("first step");
        if(business==="" || business===undefined) {
       // alert("Please enter name");
        formWizardRef.current?.goToTab(0);
        setError("Please describe your business");
        
        } 
    }

    if(nextIndex==3) {
        //alert("second step");
        if(sell==="" || sell===undefined) {
       // alert("Please enter name");
        formWizardRef.current?.goToTab(1);
        setError("Please select Products or Services");
        
        } 
    }

    if(nextIndex==4) {
        if(goals==="" || goals===undefined) {
            formWizardRef.current?.goToTab(2);
            setError("Please select your video marketing goals");
            console.log("x");
        }
    }
    if(nextIndex==5) {
        if(market==="" || market===undefined) {
            formWizardRef.current?.goToTab(3);
            setError("Please select how you market your business");
            console.log("x");
        }
    }
    

  };

    




    

    return(
        <div>
                <div className="top-bar">
                    
                    <small className='top-bar-text business' id="top-bar-text-business">{business}</small>
                    <small className='top-bar-text sell' id="top-bar-text-sell">{sell}</small>
                    <small className='top-bar-text goals' id="top-bar-text-goals">{goals}</small>
                    <small className='top-bar-text market' id="top-bar-text-market">{market}</small>
                </div>
                <div className="container" >
                
                
                <div className="row justify-content-left pt-0 " >
                    <div className="col-sm-12 pb-4">

                    

                    </div>

                   

                     
                     <div className="col-sm-12">

                     <div id="" className="new-input-box" >
                     <FormWizard
        shape="circle"
        color="#e74c3c"
        ref={formWizardRef}
        onComplete={handleComplete}
        onTabChange={tabChanged}
      >

      
        
        <FormWizard.TabContent title="" icon="">
                            <div className="ques" id="q1">
                            <p><i className="qno">1</i><i className="fa fa-long-arrow-right"></i> How would you describe your business?*</p>
                            <span>Please choose one of the below</span>
                            <div className="row">
                                <label className="col-sm-12">
                                    <div className={((business === 'B2B Business')?'highlight ':' ')+"option-container option-container-checkbox option-container-business card"} >
                                    
                                    <input type="radio" name="business" id="business_b2b" value="B2B" checked={business === 'B2B Business'} onChange={updateLabel} />
                                    <span><i>A</i>B2B</span>

                                    </div>
                                </label>

                                <label className="col-sm-12">
                                    <div className={((business === 'B2C Business')?'highlight ':' ')+"option-container option-container-checkbox option-container-business card"}>
                                    
                                    <input type="radio" name="business"  id="business_b2c" value="B2C" checked={business === 'B2C Business'} onChange={updateLabel} />
                                    <span><i>B</i>B2C</span>

                                    </div>
                                </label>
                                <div className="col-sm-12">

                                <span className="error-box">{error}</span>
                                </div>
                                
                            </div>
                            
                            
                            </div>
        </FormWizard.TabContent>


        <FormWizard.TabContent title="" icon="">
                            <div className="ques" id="q1">
                            <p><i className="qno">2</i><i className="fa fa-long-arrow-right"></i> What does your business sell?*</p>
                            <span>Please choose on of the below</span>
                            <div className="row">
                                <label className="col-sm-12">
                                    <div className={((sell === 'Products')?'highlight ':' ')+"option-container option-container-checkbox option-container-sell card"}>
                                    
                                    <input type="radio" name="sell" id="sell_products" value="Products" checked={sell === 'Products'}  onChange={updateLabelSell} />
                                    <span><i>A</i>Products</span>

                                    </div>
                                </label>

                                <label className="col-sm-12">
                                    <div className={((sell === 'Services')?'highlight ':' ')+"option-container option-container-checkbox option-container-sell card"}>
                                    
                                    <input type="radio" name="sell" id="sell_services" value="Services" checked={sell === 'Services'} onChange={updateLabelSell} />
                                    <span><i>B</i>Services</span>

                                    </div>
                                </label>
                                <div className="col-sm-12">
                                <span className="error-box">{error}</span>  
                                </div>
                                
                            </div>
                            
                            
                            </div>
        </FormWizard.TabContent>



        <FormWizard.TabContent title="" icon="">
                            <div className="ques" id="q1">
                            <p><i className="qno">3</i><i className="fa fa-long-arrow-right"></i> What are your video marketing goals?*</p>
                            <span>Choose your preferred marketing goal</span>
                            <div className="row">
                                <label className="col-sm-12">
                                    <div className={((goals === 'More Leads')?'highlight ':' ')+"option-container option-container-checkbox option-container-goals card"}>
                                    
                                    <input type="radio" name="goals" id="goal_more_lead" value="More Leads" checked={goals === 'More Leads'} onChange={updateLabelGoals} />
                                    <span><i>A</i>More Leads</span>

                                    </div>
                                </label>

                                <label className="col-sm-12">
                                    <div className={((goals === 'More Sales')?'highlight ':' ')+"option-container option-container-checkbox option-container-goals card"}>
                                    
                                    <input type="radio" name="goals" id="goal_more_sales" value="More Sales" checked={goals === 'More Sales'} onChange={updateLabelGoals} />
                                    <span><i>B</i>More Sales</span>

                                    </div>
                                </label>

                                <label className="col-sm-12">
                                    <div className={((goals === 'Increase Conversion')?'highlight ':' ')+"option-container option-container-checkbox option-container-goals card"}>
                                    
                                    <input type="radio" name="goals" id="goal_increase_conversion" value="Increase Conversion" checked={goals === 'Increase Conversion'} onChange={updateLabelGoals} />
                                    <span><i>C</i>Increase Conversion</span>

                                    </div>
                                </label>


                                <label className="col-sm-12">
                                    <div className={((goals === 'Build Our Brand')?'highlight ':' ')+"option-container option-container-checkbox option-container-goals card"}>
                                    
                                    <input type="radio" name="goals" id="goal_build_brand" value="Build Our Brand" checked={goals === 'Build Our Brand'} onChange={updateLabelGoals} />
                                    <span><i>D</i>Build Our Brand</span>

                                    </div>
                                </label>

                                <label className="col-sm-12">
                                    <div className={((goals === 'Secure Investment')?'highlight ':' ')+"option-container option-container-checkbox option-container-goals card"}>
                                    
                                    <input type="radio" name="goals" id="goal_secure_investment" value="Secure Investment" checked={goals === 'Secure Investment'} onChange={updateLabelGoals} />
                                    <span><i>E</i>Secure Investment</span>

                                    </div>
                                </label>

                                <label className="col-sm-12">
                                    <div className={((goals === 'Other')?'highlight ':' ')+"option-container option-container-checkbox option-container-goals card"}>
                                    
                                    <input type="radio" name="goals" id="goal_other" value="Other" checked={goals === 'Other'} onChange={updateLabelGoals} />
                                    <span><i>F</i>Other</span>

                                    </div>
                                </label>

                                <div className="col-sm-12">
                                    <span className="error-box">{error}</span>
                                </div>
                                
                            </div>
                            
                            
                            </div>
        </FormWizard.TabContent>



        <FormWizard.TabContent title="" icon="">
                            <div className="ques" id="q1">
                            <p><i className="qno">4</i><i className="fa fa-long-arrow-right"></i> How do you primarily market your business?*</p>
                            <span>Select the main marketing activity that drives results for your business</span>
                            <div className="row">
                                <label className="col-sm-12">
                                    <div className={((market === 'Google Paid Search')?'highlight ':' ')+"option-container option-container-checkbox option-container-market card"}>
                                    
                                    <input type="radio" name="marketing" id="marketing_google" value="Google Paid Search" checked={market === 'Google Paid Search'} onChange={updateLabelMarket} />
                                    <span><i>A</i>Google Paid Search</span>

                                    </div>
                                </label>

                                <label className="col-sm-12">
                                    <div className={((market === 'SEO')?'highlight ':' ')+"option-container option-container-checkbox option-container-market card"}>
                                    
                                    <input type="radio" name="marketing" id="marketing_seo"  value="SEO" checked={market === 'SEO'} onChange={updateLabelMarket} />
                                    <span><i>B</i>SEO</span>

                                    </div>
                                </label>

                                <label className="col-sm-12">
                                    <div className={((market === 'Email Marketing')?'highlight ':' ')+"option-container option-container-checkbox option-container-market card"}>
                                    
                                    <input type="radio" name="marketing" id="marketing_email" value="Email Marketing" checked={market === 'Email Marketing'} onChange={updateLabelMarket} />
                                    <span><i>C</i>Email Marketing</span>

                                    </div>
                                </label>


                                <label className="col-sm-12">
                                    <div className={((market === 'Social Media')?'highlight ':' ')+"option-container option-container-checkbox option-container-market card"}>
                                    
                                    <input type="radio" name="marketing" id="marketing_social_media" value="Social Media" checked={market === 'Social Media'} onChange={updateLabelMarket} />
                                    <span><i>D</i>Social Media</span>

                                    </div>
                                </label>

                                <label className="col-sm-12">
                                    <div className={((market === 'Referrals')?'highlight ':' ')+"option-container option-container-checkbox option-container-market card"}>
                                    
                                    <input type="radio" name="marketing" id="marketing_referrals" value="Referrals" checked={market === 'Referrals'} onChange={updateLabelMarket} />
                                    <span><i>E</i>Referrals</span>

                                    </div>
                                </label>

                                <label className="col-sm-12">
                                    <div className={((market === 'Sales Presentations')?'highlight ':' ')+"option-container option-container-checkbox option-container-market card"}>
                                    
                                    <input type="radio" name="marketing" id="marketing_sales" value="Sales Presentations" checked={market === 'Sales Presentations'} onChange={updateLabelMarket} />
                                    <span><i>F</i>Sales Presentations</span>

                                    </div>
                                </label>

                                
                                <div className="col-sm-12">
                                <span className="error-box">{error}</span>
                                </div>
                            </div>
                            
                            
                            </div>
        </FormWizard.TabContent>

      </FormWizard>

      <style>{`
        @import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");
      `}</style>

                     
                     
                            

                           
                    </div>

                     



                     </div>
                     
                     


                     

                     
                </div>
                </div>

                

            </div>
    )
}