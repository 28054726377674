import { useEffect, useState } from 'react';
import AuthUser from './AuthUser';





function formatAsPercentage(sum,total) {
  let num = sum/total*100;  
  if( isNaN(num)) {
    return '0%';
  } else {
        return new Intl.NumberFormat('default', {
        style: 'percent',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(num / 100);
  }
  
}

export default function Dashboard() {
    const {http} = AuthUser();
    const [userdetail,setUserdetail] = useState('');
    const [data, setData] = useState([]);
    const [cf_leads,setCF]=useState(0);

    const [data_api, setDataAPI] = useState([]);


    const [today_date,setTodayDate] = useState('');


    

    const [legends_data, setLegendsData] = useState({
        'SEO':'-',
        'PPC':'-',
        'Facebook':'-',
        'Referral':'-',
        'Relationship':'-',
        'Strategic':'-',
        'Repeat':'-',
        'Retargeting':'-',
        'Total':'-'
    });

   
    
   /* for (let i = 0; i < data.length; i++) {
     
        for (let j = 0; j < data[i].tags.length; j++) {
            
            console.log(data[i].tags[j].tag);
        }
    } */
   


      const [Data,setDataN] = useState([{
            id: 1,
            from: 'SEO',
            leads: 1,

    
        },
        {
            id: 2,
            from: 'PPC',
            leads: 1,
    
        },
        {
            id: 1,
            from: 'Facebook',
            leads: 1,
    
        }]);





    


    useEffect(()=>{
        fetchUserDetail();
       
        
    },[]);

    const fetchUserDetail = () =>{
        http.post('/me').then((res)=>{
            setUserdetail(res.data);
        });

        let myDate = document.querySelector('#to_date');
        let today = new Date();
        //myDate.value = today.toISOString().substr(0, 10);
        setTodayDate(today.toISOString().substr(0, 10));
    }

    
    

    


     
     

     


    

     function changeChartDuration(e) {
      //   console.log(e);
      //  setChartDuration(e.target.value);
        
      //  console.log(chart_duration);


      let date_range = document.getElementById("select_date_range").value;
      let from_date = document.getElementById("from_date").value;
      let to_date = document.getElementById("to_date").value;

      let elm = document.getElementById("div_from_date");
      let elm2 = document.getElementById("div_to_date");
      
      if(date_range=="range") {
            
            elm.classList.remove("hide");
            elm2.classList.remove("hide");

            from_date = document.getElementById("from_date").value;
            to_date = document.getElementById("to_date").value;

      }  else {
            elm.classList.add("hide");
            elm2.classList.add("hide");
      }   

      

            
         
          
        

     }

     function nextQues(e) {
      
      
      
    // alert(e);
    console.log("as");
        

}

    function renderElement(){
        if(userdetail){


            
            return <div>
                
                <div className="container" >
                
                
                <div className="row justify-content-left pt-0 " >
                    <div className="col-sm-12 pb-4">

                    <h1 className='mb-4'></h1>

                    </div>

                   

                     
                     <div className="col-sm-12">

                     
                     <div id="" className="new-input-box" >
                            <p><i className="fa fa-long-arrow-right"></i> What is your First Name?*</p>
                            <span>Description (optional)</span>
                            <input type="text" name="first_name" id="first_name" className="form-control" placeholder="Type your answer here..."  />
                            <button className="btn btn-primary" onClick={nextQues('first_name')}>Next</button>
                    </div>



                     </div>
                     
                     


                     

                     
                </div>
                </div>

                

            </div>
        }else{
            return <p>Loading.....</p>
        }

    }

    return(
        <div>
            
            { renderElement() }
        </div>
    )
}