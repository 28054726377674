import { Routes, Route, Link } from 'react-router-dom';
import Home from '../components/home';
import Dashboard from '../components/dashboard';
import ClosingRate from '../components/closing-rate';
import VideoType from '../components/video-type';
import LostProjects from '../components/lost-projects';

import AvgProjects from '../components/avg-projects';
import WonAmount from '../components/won-amount';
import LostAmount from '../components/lost-amount';
import LeadsPerSource from '../components/leads-per-source';
import LeadsStatus from '../components/leads-status';


import Leads from '../components/leads';
import AuthUser from '../components/AuthUser';
import { useNavigate } from 'react-router-dom';

function Auth() {
    const {token,logout} = AuthUser();
    const navigate = useNavigate();

    const logoutUser = () => {
        if(token != undefined){
            logout();
        }
    }

    const goLeads = () => {
        navigate('/leads');
    }
    const goDashboard = () => {
        navigate('/dashboard');
    }

    const goClosingRate = () => {
        navigate('/closing-rate');
    }

    const goVideoType = () => {
        navigate('/video-type');
    }

    const goLostProject = () => {
        navigate('/lost-project');
    }

    const goAvgProject = () => {
        navigate('/avg-project');
    }

    const goLostAmount = () => {
        navigate('/lost-amount');
    }

    const goWonAmount = () => {
        navigate('/won-amount');
    }

    const goLeadsPerSource = () => {
        navigate('/leads-per-source');
    }
    const goLeadsStatus = () => {
        navigate('/leads-status');
    }

    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-dark">
                

            </nav>
            <div className="container full-width" >
                <div className="row justify-content-left pt-0 " >
                     

                     <div className="col-sm-12 pl-2 pad-20 main-container">
                            
                            <Routes>
                                <Route path="/" element={<Home />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                
                            </Routes>
                     </div>
                </div>
                
            </div>
        </>
    );
}

export default Auth;
